.mobileAdd {
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  overflow: hidden;
  position: relative;

  .container {
    padding: 0;
  }

  .contentRow {
    min-height: 600px;
  }

  .textContent {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
  }

  .titleWrapper {
    margin-bottom: 2.5rem;
    text-align: left;

    h1 {
      font-size: clamp(2rem, 4vw, 2.8rem);
      font-weight: 700;
      margin-bottom: 1rem;
      color: #2d3748;
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 80px;
        height: 3px;
        background: linear-gradient(90deg, #ff8000, #ffd93d);
        border-radius: 2px;
      }
    }

    p {
      font-size: clamp(1rem, 2vw, 1.2rem);
      color: #4a5568;
      line-height: 1.6;
      margin: 0;
      max-width: 500px;
    }
  }

  .downloadSection {
    .storeLink {
      display: inline-block;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.05);
      }
    }

    .storeBadge {
      height: clamp(60px, 8vw, 100px);
      width: auto;
    }
  }

  .imageContent {
    position: relative;
    padding: 0;
    overflow: hidden;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f9fa;
  }

  .appImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.1);
    aspect-ratio: 1/1;
  }

  // Large screens (1200px and up)
  @media (min-width: 1200px) {
    .textContent {
      padding: 6rem;
    }

    .titleWrapper {
      p {
        max-width: 600px;
      }
    }
  }

  // Medium screens (992px to 1199px)
  @media (max-width: 1199px) {
    .contentRow {
      min-height: 500px;
    }

    .textContent {
      padding: 4rem 3rem;
    }
  }

  // Small screens (768px to 991px)
  @media (max-width: 991px) {
    .contentRow {
      min-height: 450px;
    }

    .textContent {
      padding: 3rem 2rem;
    }

    .imageContent {
      min-height: 450px;
    }
  }

  // Mobile screens (below 768px)
  @media (max-width: 767px) {
    .contentRow {
      flex-direction: column-reverse;
      min-height: auto;
    }

    .textContent {
      padding: 3rem 1.5rem;
      text-align: center;

      .titleWrapper {
        text-align: center;
        margin-bottom: 2rem;

        h1 {
          &::after {
            left: 50%;
            transform: translateX(-50%);
          }
        }

        p {
          margin: 0 auto;
        }
      }
    }

    .imageContent {
      min-height: 350px;
    }

    .appImage {
      transform: scale(1.2);
    }
  }

  // Extra small screens (below 576px)
  @media (max-width: 575px) {
    .textContent {
      padding: 2.5rem 1rem;
    }

    .imageContent {
      min-height: 300px;
    }

    .downloadSection {
      .storeBadge {
        height: 80px;
      }
    }
  }
}