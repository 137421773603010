.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 72px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;

  &.scrolled {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(20px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 0;

    img {
      height: 50px;
      width: auto;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;
    list-style: none;
    padding: 0;
  }

  .navItem {
    position: relative;
  }

  .navLink {
    color: #333;
    font-weight: 500;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: all 0.3s ease;
    white-space: nowrap;
    display: block;

    &:hover {
      color: #000;
    }

    &.active {
      color: #000;
      font-weight: 600;
    }
  }

  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 1rem 0;
    min-width: 200px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease;
    list-style: none;

    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  .dropdownItem {
    color: #333;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
    display: block;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
      color: #000;
      transform: translateX(5px);
    }

    &.active {
      background: rgba(0, 0, 0, 0.05);
      color: #000;
      font-weight: 600;
    }
  }

  .toggler {
    display: none;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;

    @media (max-width: 991px) {
      display: block;
    }
  }

  .togglerIcon {
    display: block;
    width: 24px;
    height: 2px;
    background: #333;
    position: relative;
    transition: all 0.3s ease;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #333;
      transition: all 0.3s ease;
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  }

  @media (max-width: 991px) {
    .collapse {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.95);
      padding: 1rem;
      
      &.show {
        display: block;
      }
    }

    .nav {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }

    .dropdownMenu {
      position: static;
      box-shadow: none;
      border: none;
      padding: 0.5rem 0;
      padding-left: 1rem;
    }
  }
}
