.footer {
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  padding: 4rem 0 2rem;
  font-size: 14px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 -8px 32px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 3rem 0 1.5rem;
  }
}

.footerInfo {
  margin-bottom: 2rem;

  .blogo {
    height: 60px;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      height: 50px;
    }
  }

  h5 {
    margin: 1rem 0;
    color: darken(#FF8000, 20%);
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}

.socialLinks {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #FF8000;
    color: white;
    border-radius: 50%;
    transition: all 0.3s ease;

    &:hover {
      background: darken(#FF8000, 10%);
      transform: translateY(-2px);
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .twitter {
    background: #FF8000;

    &:hover {
      background: darken(#FF8000, 15%);
    }
  }

  .facebook {
    background: #FF8000;
    opacity: 0.95;

    &:hover {
      opacity: 1;
      background: darken(#FF8000, 15%);
    }
  }

  .instagram {
    background: #FF8000;
    opacity: 0.9;

    &:hover {
      opacity: 1;
      background: darken(#FF8000, 15%);
    }
  }

  .linkedin {
    background: #FF8000;
    opacity: 0.85;

    &:hover {
      opacity: 1;
      background: darken(#FF8000, 15%);
    }
  }
}

.downloadApp {
  margin-top: 1.5rem;


  .playstore {
    height: 120px;
    border-radius: 12px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    @media (max-width: 768px) {
      height: 90px;
    }
  }
}

.footerLinks {
  h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
    color: darken(#FF8000, 20%);

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      background: #FF8000;
      bottom: 0;
      left: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px 0;
      display: flex;
      align-items: center;

      &:first-child {
        padding-top: 0;
      }

      a {
        color: #444444;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #FF8000;
        }
      }
    }
  }
}

.footerContact {
  h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
    color: darken(#FF8000, 20%);

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      background: #FF8000;
      bottom: 0;
      left: 0;
    }
  }

  p {
    line-height: 26px;
    color: #444444;

    .companyInfo {
      display: block;
      margin-bottom: 8px;
      font-weight: 500;
      color: darken(#FF8000, 15%);
    }

    strong {
      display: inline-flex;
      align-items: center;
      margin-right: 8px;

      svg {
        width: 16px;
        height: 16px;
        color: #FF8000;
      }
    }
  }
}

.copyrightContainer {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 128, 0, 0.2);
  text-align: center;
}

.copyright {
  h4 {
    font-size: 14px;
    font-weight: normal;
    color: #444444;

    strong {
      color: darken(#FF8000, 20%);
    }
  }
}