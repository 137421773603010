.loadingSpinner {
  display: flex;
  align-items: center;
  justify-content: center;

  &.size-sm {
    .spinnerBorder {
      width: 1.5rem;
      height: 1.5rem;
      border-width: 0.15rem;
    }
  }

  &.size-md {
    .spinnerBorder {
      width: 2.5rem;
      height: 2.5rem;
      border-width: 0.25rem;
    }
  }

  &.size-lg {
    .spinnerBorder {
      width: 3.5rem;
      height: 3.5rem;
      border-width: 0.3rem;
    }
  }

  // Only apply fullscreen styles when used as a page loader
  &:not(.size-sm):not(.size-md) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
  }
}

.spinnerBorder {
  display: inline-block;
  border: 0.25rem solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: animateLoading 1s linear infinite;
  color: #ff8000;
}

@keyframes animateLoading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
